import React from "react";
import {OpLogListRequest} from "../../../../../api/oplogs";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";
import StrategicLogsFilters from "../../Components/Shared/StrategicLogsFilters";
import TacticalLogsFilters from "../../Components/Shared/TacticalLogsFilters";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import {useOpLogBaseFilters} from "./useOpLogBaseFilters";

export const QueryStrings = {
    departmentId: "departmentId",
    sapType: "sapType",
    callSign: "callSign",
    type: "type",
    author: "author",
    pageNum: "pageNum",
    minDate: "minDate",
    maxDate: "maxDate",
    strategicIntention: "strategicIntention"
};

export function useOpLogsTableFilters(defaultLogType: LogTypeFilters) {
    const [request, setRequest] = useState<OpLogListRequest>();
    const {getOpLogsListRequestFromQuery, buildUrlHistory} = useOpLogBaseFilters();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const initialRequest = getOpLogsListRequestFromQuery(defaultLogType);

        updateRequest(initialRequest);
    }, []);

    function updateRequest(entity: OpLogListRequest) {
        const updatedEntity: OpLogListRequest = {
            ...entity,
            numPerPage: pagedRequestConfig.resultsPerPage
        };
        setRequest(updatedEntity);

        const search = buildUrlHistory(updatedEntity);

        history.push({
            search: `?${search}`
        });

        updateNavigationItems(`?${search}`);
    }

    function updateNavigationItems(searchQueries: string) {
        const navItems: NavigationItem[] = [getLogListRoute(defaultLogType, searchQueries)];

        dispatch(setNavigationItems(navItems));
    }

    function getLogListRoute(logType: LogTypeFilters, search: string): NavigationItem {
        switch (logType) {
            case LogTypeFilters.Strategic:
                return {
                    name: routeNames.strategicLogList.name,
                    path: `${routeNames.strategicLogList.path}${search}`
                };
            case LogTypeFilters.Tactical:
                return {
                    name: routeNames.tacticalLogList.name,
                    path: `${routeNames.tacticalLogList.path}${search}`
                };
        }
    }

    function renderFilters() {
        switch (defaultLogType) {
            case LogTypeFilters.Strategic:
                return (
                    <StrategicLogsFilters
                        request={request}
                        updateRequest={(entity) => updateRequest({...entity, pageNum: 0})}
                    />
                );
            case LogTypeFilters.Tactical:
                return (
                    <TacticalLogsFilters
                        request={request}
                        updateRequest={(entity) => updateRequest({...entity, pageNum: 0})}
                    />
                );
        }
    }

    return {
        request,
        updateRequest,
        renderFilters
    };
}
